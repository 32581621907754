<template>
  <el-tabs
    class="tabs"
    type="border-card">
    <div
      v-for="({ name, title }, key) in tabs"
      :key="key"
      class="tabs__item">
      <router-link :to="{ name }">
        {{ title }}
      </router-link>
    </div>
  </el-tabs>
  <router-view />
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  },
  setup (props, ctx) {
    return {
    }
  }
}

</script>

<style lang="scss" scoped>
  .tabs {
    &__item {
      a {
        text-decoration: none;
      }
    }
  }
</style>
