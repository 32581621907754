<template>
  <div class="report-item">
    <ReportForm :report="report" />
    <WidgetRouterTabs :tabs="tabs" />
  </div>
</template>

<script>
import WidgetRouterTabs from '@/components/widgets/WidgetRouterTabs'
import ReportForm from './ReportForm'
export default {
  name: 'ReportItem',
  components: {
    ReportForm,
    WidgetRouterTabs
  },
  data () {
    return {
      tabs: [{
        name: 'ReportJournal',
        title: 'Журнал'
      }]
    }
  },

  computed: {
    ...mapGetters('report', ['report'])
  },

  created () {

  },

  methods: {

  }
}
</script>

<style lang="scss">
</style>
